import { conditionSubtypes } from '@components/app/TrialSearch/consts/conditionSubtypes'
import type { TrialInDatabaseForAlgolia } from '@modules/trials/types/TrialInDatabaseForAlgolia'
import intersection from 'lodash/intersection'
import uniq from 'lodash/uniq'

export const getSearchTermsFromConditionsList = (
  conditions: TrialInDatabaseForAlgolia['conditions'],
) => {
  const conditionsList = uniq([
    ...conditions.map(getSearchTermsFromCondition),
    ...(conditions
      .map(getAcronymsForCondition)
      .filter(isValidAcronym) as string[]),
  ])

  return conditionsList
}

export const getSearchTermsFromConditionsWithSubtypesList = (
  conditions: TrialInDatabaseForAlgolia['conditions'],
) => {
  const conditionsList = getSearchTermsFromConditionsList(conditions)

  // Do not recurse through the added subtypes
  const additionalSubtypes = getAdditionalSubtypes(conditionsList)

  return uniq(conditionsList.concat(additionalSubtypes))
}

const getSearchTermsFromCondition = (
  condition: TrialInDatabaseForAlgolia['conditions'][0],
) => {
  return (
    condition.condition.colloquialTermStripped ?? // preference the stripped term for the search terms
    condition.condition.colloquialTerm ??
    condition.condition.name
  )
}
export const getAcronymsForCondition = (
  condition: TrialInDatabaseForAlgolia['conditions'][0],
) => {
  return condition.condition.colloquialAcronym
}

export const isValidAcronym = (acronym: string | 'N/A' | null) => {
  return !!acronym && acronym !== 'N/A'
}

export const getAdditionalSubtypes = (conditions: string[]) => {
  return conditions.reduce(
    (subtypes: string[], condition, _index, allConditions) => {
      // Find a condition subtype group that has matches this condition but not too many subtypes
      const conditionSubtype = conditionSubtypes.find(
        (conditionSubtype) =>
          conditionSubtype.condition === condition &&
          conditionSubtype.subtypes.length < 20, // Manually inspected limit, e.g.: don't include _all_ cancer subtypes
      )
      if (!conditionSubtype) {
        return subtypes
      }

      // Skip if any subtypes are already in conditions list
      const hasSubtypesInConditionList =
        intersection(conditionSubtype.subtypes, allConditions).length > 0
      if (hasSubtypesInConditionList) {
        return subtypes
      }

      subtypes.push(...conditionSubtype.subtypes)

      return subtypes
    },
    [],
  )
}
