import { NavBar } from '@components/app/NavBar'
import SearchBar from '@components/app/TrialSearch/SearchBar'
import { searchParamFromUrl } from '@components/hooks/ParamsProvider/searchParams'
import {
  DEFAULT_SEARCH_STATE,
  useSearchUrlParameters,
} from '@components/hooks/ParamsProvider/useSearchUrlParameters'
import useIsPartnerPage from '@components/hooks/useIsPartnerPage'
import { classMerge } from '@components/utilities/classMerge'
import { useEffect } from 'react'
import { cancerSubtypes } from './consts/cancerSubtypes'

type SearchBarProps = {
  autoFocus: boolean
  conditionName?: string
  interventionPlaceholder?: string
  isSearchPage: boolean
  locationPlaceholder?: string
  logoImageId?: string
  sticky: boolean
  transparentSearchBackground: boolean
}

export const SearchBarVariants = (props: SearchBarProps) => {
  const isPartnerPage = useIsPartnerPage({ includeEmbedPage: false })
  if (isPartnerPage) {
    return (
      <div className='z-filterBar mx-auto mb-12 max-w-5xl p-4'>
        <SearchBar autoFocus={false} isSearchPage={true} />
      </div>
    )
  } else if (props.isSearchPage) {
    return <SearchPageSearchBar {...props} />
  }

  return (
    <>
      <div className={classMerge(props.sticky && 'sticky top-0 z-filterBar')}>
        <div className='z-filterBar mx-auto max-w-5xl p-4'>
          <SearchBar
            {...props}
            conditionPlaceholder={props.conditionName}
            shadowStyle='shadow-xl shadow-baby600/50 transition-shadow ease-in-out hover:shadow-baby700/50'
          />
        </div>
      </div>
    </>
  )
}

const SearchPageSearchBar = (props: SearchBarProps) => {
  const { updateUrlToReflectSearchState } = useSearchUrlParameters()

  useEffect(() => {
    const condition = searchParamFromUrl('condition')

    if (condition && cancerSubtypes.includes(condition)) {
      updateUrlToReflectSearchState({
        ...DEFAULT_SEARCH_STATE,
        hasNoPlacebo: 'true',
      })
    } else {
      updateUrlToReflectSearchState({
        ...DEFAULT_SEARCH_STATE,
        hasNoPlacebo: 'false',
      })
    }
  }, [])

  return (
    <>
      <div className='hidden md:flex'>
        <NavBar
          compactAccountButtons
          height='h-[140px]'
          hiddenCta={true}
          includeNavigationLinks={false}
          logoImageId={props.logoImageId}
          maxWidth='max-w-screen-2xl'
        >
          <div className='max-w-3xl py-6'>
            <SearchBar {...props} />
          </div>
        </NavBar>
      </div>
      <div className='overflow-visible md:hidden'>
        <NavBar
          compactAccountButtons
          hiddenCta={true}
          includeNavigationLinks={false}
          logoImageId={props.logoImageId}
          showBurger={false}
        />
        <div className='mt-8'>
          <SearchBar {...props} />
        </div>
      </div>
    </>
  )
}
