import subYears from 'date-fns/subYears'
import type { TrialInDatabaseForAlgolia } from '../types/TrialInDatabaseForAlgolia'

export default function hasUpdateInLastTwoYears(
  trial: TrialInDatabaseForAlgolia,
  anchor = new Date(),
) {
  if (trial.lastUpdatedDate === null) {
    return true
  }

  const twoYearsAgo = subYears(anchor, 2)
  return trial.lastUpdatedDate > twoYearsAgo
}
