import { TrialReimbursementType } from '@lib/types/TrialReimbursementType'
import type { TrialAssociation } from '@modules/trials/types/TrialAssociation'
import uniq from 'lodash/uniq'
import { hasMatch, parseInclusionStringFromTrial } from '.'
import type { FilterItem } from './ngramData'
import { reimbursementFilterOptions } from './ngramData'

export default function getReimbursementTagsFromTrial(trial: TrialAssociation) {
  // First check if the trial has any reimbursements explicitly set via SSP
  if (trial.reimbursements.length > 0) {
    const reimbursementTags = []

    const travelReimbursement = trial.reimbursements.find(
      (reimbursement) => reimbursement.type === TrialReimbursementType.Travel,
    )
    const otherReimbursement = trial.reimbursements.find(
      (reimbursement) => reimbursement.type === TrialReimbursementType.Other,
    )

    if (travelReimbursement && travelReimbursement.amount > 0) {
      reimbursementTags.push('Travel Reimbursement')
    }

    if (otherReimbursement && otherReimbursement.amount > 0) {
      reimbursementTags.push('Other Reimbursement')
    }

    return reimbursementTags
  }

  // If not, check if the trial has any reimbursement keywords
  const trialTextContent = parseInclusionStringFromTrial(trial)
  const trialMatchesTag = (tag: FilterItem) =>
    tag.matches.some((match) => hasMatch(trialTextContent, match))

  const reimbursementTags = reimbursementFilterOptions
    .filter(trialMatchesTag)
    .map((tag) => tag.label)

  return uniq(reimbursementTags).sort()
}
