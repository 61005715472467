import type { TrialAssociation } from '@modules/trials/types/TrialAssociation'
import uniq from 'lodash/uniq'
import { isModuleCondition } from './isAuthoritativeCondition'

interface GetAllConditionNamesOptions {
  authoritativeOnly: boolean
}

/**
 * Get a list of strings representing the canonical conditions given a trial mapping of ClinicalTrialCondition
 *
 * @param conditions trial.conditions or ClinicalTrialCondition
 * @param options.authoritativeOnly Boolean on whether to include researcher-only inputted conditions
 */
export const getAllConditionNames = (
  conditions: TrialAssociation['conditions'],
  { authoritativeOnly = false }: GetAllConditionNamesOptions = {
    authoritativeOnly: false,
  },
) => {
  const primaryCondition = conditions.find(
    (condition) => condition.isPrimaryCondition,
  )
  const additionalConditions = conditions.filter(
    (condition) =>
      !condition.isPrimaryCondition && isModuleCondition(condition),
  )

  const conditionNames = uniq([
    primaryCondition?.condition.colloquialTerm ??
      primaryCondition?.condition.name,
    ...additionalConditions.map(
      (condition) =>
        condition.condition.colloquialTermStripped ??
        condition.condition.colloquialTerm ??
        condition.condition.name,
    ),
  ]).filter(Boolean)

  // If there is no primary condition or any conditions with `inConditionsModule`
  // set true, then use any conditions that are present
  if (!authoritativeOnly && conditionNames.length === 0) {
    return conditions
      .map(
        (condition) =>
          condition.condition.colloquialTermStripped ??
          condition.condition.colloquialTerm ??
          condition.condition.name,
      )
      .filter(Boolean)
  }

  return conditionNames
}
