import { ALGOLIA_CATEGORY_SEPARATOR } from '@lib/database/algoliaCategorySeparator'
import isArray from 'lodash/isArray'

export const AlgoliaHierarchicalMenuRecordExample = {
  ['conditionTree.lvl0']: [
    'Immune System Diseases',
    'Pathological Conditions, Signs and Symptoms',
    'Musculoskeletal Diseases',
  ],
  ['conditionTree.lvl1']: [
    'Immune System Diseases > Autoimmune Diseases',
    'Pathological Conditions, Signs and Symptoms > Pathologic Processes',
    'Musculoskeletal Diseases > Joint Diseases',
  ],
  ['conditionTree.lvl2']: [
    'Immune System Diseases > Autoimmune Diseases > Arthritis, Juvenile',
    'Pathological Conditions, Signs and Symptoms > Pathologic Processes > Sclerosis',
    'Musculoskeletal Diseases > Joint Diseases > Arthritis',
  ],
  ['conditionTree.lvl3']: [
    'Musculoskeletal Diseases > Joint Diseases > Arthritis > Arthritis, Juvenile',
  ],
}

export type AlgoliaHierarchicalMenuRecord = Record<
  keyof typeof AlgoliaHierarchicalMenuRecordExample | string,
  string[]
>

export default function convertLabelledUmlsTreeNumberToCategoryLevels(
  labelledUmlsTreeNumber: string,
  conditionTreeRecord: AlgoliaHierarchicalMenuRecord = {},
) {
  const splitLabels = labelledUmlsTreeNumber.split(ALGOLIA_CATEGORY_SEPARATOR)

  return splitLabels.reduce(
    convertLeafWithLabel('conditionTree'),
    conditionTreeRecord,
  )
}

export function convertLeafWithLabel(rootKey: string) {
  return (
    conditionTree: AlgoliaHierarchicalMenuRecord,
    _label: string,
    index: number,
    splitLabels: string[],
  ) => {
    const key = `${rootKey}.lvl${index}`
    const value = splitLabels
      .slice(0, index + 1)
      .join(ALGOLIA_CATEGORY_SEPARATOR)

    if (conditionTree[key] && isArray(conditionTree[key])) {
      conditionTree[key]!.push(value)
    } else {
      conditionTree[key] = [value]
    }

    return conditionTree
  }
}
