import { classMerge } from '@components/utilities/classMerge'
import { type BackgroundColor } from '@lib/colors'
import styles from './styles.module.scss'

export const ShimmerProgressBar = ({
  bgColor,
  className,
}: {
  bgColor: BackgroundColor
  className?: string
}) => {
  return (
    <div className={classMerge('relative w-full', className)}>
      <div
        className={classMerge(
          'relative top-0 overflow-hidden opacity-80',
          className,
          bgColor,
        )}
      >
        <div className={`${styles.shimmer}`}></div>
      </div>
    </div>
  )
}
